const PompReg = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg- p-6">
            <h1 className="text-2xl font-bold mb-4 text-gray-800">Fill the Form</h1>
            <iframe
                src="https://www.cognitoforms.com/f/uO_y5Ry4Gky2Z1XZ16P7cw/3"
                width="100%"
                height="800px"
                style={{ border: "none" }}
                title="Cognito Form"
            ></iframe>
        </div>
    );
};

export default PompReg;
