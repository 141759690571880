import { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import projects from '../data/projectData';
import { LiaCheckSquare } from 'react-icons/lia';




const ProjectDetail = () => {
  const { id } = useParams();
  const project = projects.find((proj) => proj.id === Number(id));
  const navigate = useNavigate()
  const location = useLocation();  
  const currentPath = location.pathname;

 // Redirect if ID is 1
 useEffect(() => {
  if (currentPath === "/pomp-registration") {
    navigate("/projects/1", { replace: true });
  }
}, [id,currentPath, navigate]);


if (!project) {
  return <p className="text-center text-gray-700 text-lg">Loading . . .</p>;
}
 
  return (
    <div className="p-2 md:p-6 bg-gray-50 min-h-screen">
      {project ? (
        <>
          <h1 className="text-font text-4xl md:text-5xl font-bold text-center text-black mb-10">
            {project.title}
          </h1>

          {/* Project Details */}
          <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">

          <div className={` ${project.id === 1 ? "block" : "hidden"} relative`}>
              <img
                src={project.image}
                alt={project.title}
                className="rounded-md w-full md:w-1/3 float-left mr-4 mb-4"
              />
              <p className="text-font text-base text-justify">{project.description}</p>
            </div>
            {/* Render structured details if available */}
            {project.details ? (
              <>
                {project.details["About POMP para1"] && (
                  <>
                    <h2 className="text-font text-2xl font-semibold text-gray-800 mb-4">About POMP</h2>
                    <p className="text-font text-gray-600 leading-relaxed">
                      {project.details["About POMP para1"]}
                    </p>
                  </>
                )}
                {project.details["About POMP para2"] && (
                  <>
                    <p className="text-gray-600 leading-relaxed mt-5">
                      {project.details["About POMP para2"]}
                    </p>
                  </>
                )}

                {project.details["Why Join POMP?"] && (
                  <>
                    <h2 className="text-font text-2xl font-semibold text-gray-800 mt-8">Why Join POMP?</h2>
                    <ul className="text-font list-disc list-inside space-y-2 mt-2">
                      {project.details["Why Join POMP?"].map((item, index) => (
                        <li key={index} className="text-gray-700 flex gap-1 list-none lg:items-center">
                          <LiaCheckSquare className=' text-blue-600 font-bold text-2xl' />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {project.details["Program Structure"] && (
                  <div className="my-10 relative lg:z-10 bg-[rgb(255,255,255,0.9)] p-3">
                    <img src={project.img_banner} alt="" className='absolute left-0 top-0 w-full h-full opacity-[0.4] hidden lg:block' />
                    <h2 className="text-font text-2xl font-semibold text-gray-800 mt-8 p-2">Program Structure</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                      {Object.entries(project.details["Program Structure"]).map(([key, value], index) => (
                        <div key={index} className="p-4 bg-[rgba(255,255,255,0.5)] rounded-md shadow-sm relative z-30 lg:opacity-70">
                          <h3 className="text-font text-lg font-bold text-blue-950 lg:opacity-[1]">{key}</h3>
                          <p className="text-black font-semibold lg:opacity-[1]">{value}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <h2 className='text-font text-2xl font-semibold text-gray-800 mt-14'>POMP Achievements</h2>
                {project.details.Achievements.map((achieve, index) => (
                  <div key={index} className="flex items-center gap-2 mt-5">
                    <img src={achieve.icon} alt="Achievement Icon" className="w-6 h-6" />
                    <p>{achieve.text}</p>
                  </div>
                ))}


                {project.details["Join POMP 5.0 – Registration Now Open!"] && (
                  <>
                    <h2 className="text-font text-2xl font-semibold text-gray-800 mt-20">Join POMP 5.0 – Registration Now Open!</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                      {Object.entries(project.details["Join POMP 5.0 – Registration Now Open!"]).map(([key, value], index) => (
                        <div key={index} className="p-4 bg-gray-100 rounded-md shadow-sm">
                          <h3 className="text-lg font-bold text-blue-700">{key}</h3>
                          <p className="text-gray-700">{value}</p>
                        </div>
                      ))}
                    </div>
                  </>
                )}
                

                {project.details["Apply Now"] && (
                <>
                  <h2 className="text-2xl text-font font-semibold text-gray-800 mt-8">Apply Now</h2>
                  <div className="text-gray-700 flex flex-col lg:flex-row gap-2 items-center mb-5">
                    <p>{project.details["Apply Now"]} : </p> 
                  <a href={`mailto:${project.details["Email1"]}`} className="text-blue-500 underline block">
                    {project.details["Email1"]}
                  </a>
                  <span> Or </span>
                  <a href={`mailto:${project.details["Email2"]}`} className="text-blue-500 underline block">
                    {project.details["Email2"]}
                  </a>
                  </div>
                </>
              )}


                {project.details.form && (
                  <div
                    className="mt-6"
                    dangerouslySetInnerHTML={{ __html: project.details.form }}
                  />
                )}
              </>
            ) : (
              <div className="relative">
              <img
                src={project.image}
                alt={project.title}
                className="rounded-md w-full md:w-1/3 float-left mr-4 mb-4"
              />
              <p className="text-base text-justify">{project.description}</p>
            </div>
            )}



            {project.title === "Prince Orji Mentorship Program (POMP)" ?
               
               <Link to="/pomp-registrations" className='bg-blue-600 px-4 py-2 rounded-sm text-white font-text mt-8'>Register</Link>
                  :
                <></>
            }

          </div>
        </>
      ) : (
        <p className="text-center text-gray-700 text-lg">Project not found.</p>
      )}
    </div>
  );
};

export default ProjectDetail;
